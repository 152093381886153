import React from "react";
//Import index.css
import "./index.css";
function App() {
  return (
    <header className="text-center p-4 items-center justify-center flex flex-col h-screen from-gray-200 to-white bg-gradient-to-br dark:from-primary-800 dark:to-primary-700 dark:bg-gradient-to-br">
      <h1 className="text-7xl md:text-9xl lg:text-10xl font-montserrat font-bold ">
        <span className="bg-clip-text text-transparent  dark:text-primary-50 bg-gradient-to-tl dark:bg-transparent from-primary-600 to-violet-700 ">rivdevs</span>
      </h1>
      <h4 className="text-2xl text-black dark:text-gray-300">Team of enthusiast <span className="text-primary dark:text-primary-300"> engineers</span> and <span className="text-primary dark:text-primary-300">developers</span></h4>
      <p className="py-4 text-gray-400">The website is coming soon</p>
      <address className="not-italic text-center fixed bottom-4 left-0 right-0 text-gray-400 hover:text-primary-300"><a href="mailto:rivdevs@gmail.com">rivdevs@gmail.com</a></address>
    </header>
  );
}

export default App;